import { useCallback } from 'react';
import type { HeadersFunction, MetaFunction } from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';
import { jwtDecode } from 'jwt-decode';
import { json, redirect, type LoaderFunctionArgs } from '@remix-run/node';
import CleengProvider from '~/components/storefront-providers/Cleeng';
import StripeProvider from '~/components/storefront-providers/Stripe';
import NewNavBar, {
  getCurrentNavBar,
} from '~/services/plugins/nav-bar/components';
import {
  cssVarsMetaFunctionData,
  getThemeVars,
} from '~/services/css-vars/index.ts';
import { getTranslations } from '~/services/localization/index.ts';
import { getConfig } from '~/services/config/index.ts';
import type { NavigationHandler } from '~/services/layout/navigations.ts';
import { navigationHandler } from '~/services/layout/navigations.ts';
import { getStaticLinks } from '~/services/router/index.server';
import { fetchFeed } from '~/services/pipes/index.server.ts';
import { type AuthData } from '~/services/plugins/payment-info/components/index.tsx';
import type { ZappNavigation } from '~/services/layout/types';
import logger from '~/services/logger';
import { redirectToNotFoundScreen } from '~/services/layout/index.server';
import { getAnalyticsSharedVars } from '~/services/analytics/shared-vars.server.ts';
import { getBaseURL } from '~/utils/get-base-url.server';
import { useSetIsLoggedInFromServerLoader } from '~/hooks/use-is-logged-in';
import { sessionCookie } from '~/services/session/session.server';
import { getServerIsLoggedIn } from '~/services/login';
import { sessionAuthKey } from '~/services/login/session-auth-key';

const cacheControl = 'no-store, no-cache, must-revalidate';

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  const metaTags: any[] = [
    ...cssVarsMetaFunctionData({
      data,
      routePath: 'css-vars',
    }),
    ...cssVarsMetaFunctionData({
      data,
      routePath: 'css-vars-v2',
    }),
  ];

  return metaTags;
};

export function getWebStorefrontScreen(screens: any[]) {
  return screens.find((screen) => screen.type === 'web-storefront');
}

export const PAYMENT_PROVIDER_CLEENG = 'Cleeng';
export const PAYMENT_PROVIDER_STRIPE = 'stripe';
export const PAYMENT_PROVIDER_INPLAYER = 'inplayer';
export const PAYMENT_PROVIDER_ZYPE = 'zype';
export const PAYMENT_PROVIDER_BRIGHTCOVE = 'brightcove';

export const headers: HeadersFunction = () => ({
  'Cache-Control': cacheControl,
});

export async function loader({ request }: LoaderFunctionArgs) {
  try {
    const url: URL = new URL(request.url);

    const baseURL = getBaseURL();
    const { layout, pluginConfigurations, cellStyles, endpoints } =
      await getConfig();

    const analyticsSharedVars = await getAnalyticsSharedVars(layout.id);

    const originEntryUrl = url.searchParams.get('originEntryUrl');

    let successRedirectLink = originEntryUrl
      ? `/successful-payment?originEntryUrl=${originEntryUrl}`
      : '/successful-payment';

    const { screens, navigations } = layout;
    const screen = getWebStorefrontScreen(screens);

    if (!screen) throw new Error('Screen not found');

    const { authentication_feed_url } = screen?.data;

    const authData = await fetchFeed({
      path: authentication_feed_url.source,
      endpoints,
      pluginConfigurations,
      request,
    });

    const paymentProvider = screen?.general?.select_payment_provider;

    // stripe hosted version hardcoded for now
    const isStripeHostedCheckout =
      paymentProvider === PAYMENT_PROVIDER_STRIPE ||
      paymentProvider === PAYMENT_PROVIDER_ZYPE ||
      paymentProvider === PAYMENT_PROVIDER_BRIGHTCOVE ||
      paymentProvider === PAYMENT_PROVIDER_INPLAYER;

    if (isStripeHostedCheckout) {
      const priceId = url.searchParams.get('priceId');
      const referer = request.headers.get('Referer');

      let successUrl = `${url.origin}${successRedirectLink}`;
      const backUrl = referer || url.origin;

      const successCompletionAction =
        screen?.general.stripe_success_completion_action;

      if (successCompletionAction === 'go_home') {
        successUrl = url.origin;
      }

      if (successCompletionAction === 'go_back') {
        successUrl = backUrl;
      }

      const payload = {
        priceId: priceId,
        hosted: true,
        provider: paymentProvider,
        successUrl: successUrl,
        cancelUrl: backUrl,
        productId: url.searchParams.get('productId'),
      };

      if (paymentProvider === PAYMENT_PROVIDER_INPLAYER) {
        // @ts-ignore
        const claims = jwtDecode(authData?.feed?.access_token);
        // @ts-ignore
        payload.userId = claims?.tid;
      }

      try {
        const response = await fetch(
          `${url.origin}/api/stripe-create-payment-session`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          }
        );

        const data = await response.json();

        return redirect(data.redirectUrl);
      } catch (error) {
        return json(
          { error: 'Error creating payment session' },
          { status: 500 }
        );
      }
    }

    const themeVars = getThemeVars(pluginConfigurations);

    const screenNavigations: NavigationHandler = navigationHandler({
      navigations,
      screens,
      urlPathname: url.pathname,
    });

    const newNavigations = Object.entries(navigations).reduce(
      (acc, [id, nav]) => {
        acc[id] = nav as ZappNavigation;
        return acc;
      },
      {} as Record<string, ZappNavigation>
    );

    const localizations = getTranslations(screen.localizations);

    const navBar = getCurrentNavBar(screen, newNavigations);

    if (!authentication_feed_url)
      throw new Error('Authentication feed url not found');

    const { session } = await sessionCookie(request, pluginConfigurations);
    const authInfo = session.get(sessionAuthKey);
    const isLoggedIn: boolean = getServerIsLoggedIn(authInfo);

    const authDataFeed = authData?.feed as unknown as AuthData;

    if (!authDataFeed?.access_token || !isLoggedIn) {
      return redirect(`/login?redirectBack=${encodeURIComponent(request.url)}`);
    }

    const analyticsTrackData = {
      ...analyticsSharedVars,
      layout_id: layout.id,
    };

    return json(
      {
        screen,
        themeVars,
        cellStyles,
        navigations: screenNavigations,
        localizations,
        staticLinks: getStaticLinks(screens),
        pluginConfigurations,
        endpoints,
        request,
        authData: authDataFeed,
        navBar,
        successRedirectLink,
        analyticsTrackData,
        baseURL,
        APP_VERSION_UUID: process.env.APP_VERSION_UUID,
        isLoggedIn,
      },
      {
        status: 200,
        headers: {
          'Cache-Control': cacheControl,
        },
      }
    );
  } catch (error: any) {
    logger.info(`/payment route loader: ${error.message}`);
    return await redirectToNotFoundScreen();
  }
}

export default function Index() {
  // @ts-ignore
  const { screen, localizations } = useLoaderData<typeof loader>();

  useSetIsLoggedInFromServerLoader();

  const { use_in_app_flow, select_payment_provider } = screen?.general;

  const renderStorefrontProvider = useCallback(() => {
    if (
      use_in_app_flow &&
      select_payment_provider === PAYMENT_PROVIDER_CLEENG
    ) {
      return <CleengProvider config={screen} localizations={localizations} />;
    } else if (
      use_in_app_flow &&
      select_payment_provider === PAYMENT_PROVIDER_STRIPE
    ) {
      return <StripeProvider config={screen} localizations={localizations} />;
    }
  }, [screen, use_in_app_flow, select_payment_provider, localizations]);

  return (
    <>
      <NewNavBar />
      {renderStorefrontProvider()}
    </>
  );
}
