const manifest = {
  plugin_id: 3425,
  api: {},
  dependency_repository_url: [],
  dependency_name: '',
  author_name: 'Applicaster',
  author_email: 'zapp@applicaster.com',
  name: 'Web Storefront',
  description: 'Web Storefront',
  type: 'payments',
  screen: true,
  react_native: true,
  identifier: 'web-storefront',
  ui_builder_support: true,
  whitelisted_account_ids: [],
  deprecated_since_zapp_sdk: '',
  unsupported_since_zapp_sdk: '',
  targets: ['web'],
  ui_frameworks: ['quickbrick'],
  platform: 'web',
  dependency_version: '1.0.0',
  manifest_version: '1.0.28',
  min_zapp_sdk: '0.0.1',
  general: {
    fields: [
      { key: 'storefront_url', label: 'Storefront URL', type: 'text_input' },
      {
        key: 'use_in_app_flow',
        label: 'Use In-app Flow',
        type: 'switch',
        initial_value: false,
      },
      {
        key: 'nav_bar_id',
        label: 'Navigation Bar',
        placeholder: 'Choose Nav Bar',
        type: 'nav_bar_selector',
      },
      {
        key: 'select_payment_provider',
        label: 'Select Payment Provider',
        type: 'select',
        options: [
          {
            text: 'Cleeng',
            value: 'Cleeng',
          },
          {
            text: 'JWPlayer (inPlayer)',
            value: 'inplayer',
          },
          {
            text: 'Stripe',
            value: 'stripe',
          },
          {
            text: 'Zype',
            value: 'zype',
          },
          {
            text: 'Brightcove',
            value: 'brightcove',
          },
        ],
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'use_in_app_flow',
            condition_value: true,
          },
        ],
      },
      {
        key: 'stripe_hosted_checkout',
        label: 'Stripe hosted checkout',
        type: 'switch',
        rules: 'conditional',
        initial_value: true,
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'stripe',
          },
        ],
      },
      {
        key: 'stripe_publishable_key',
        label: 'Stripe publishable key',
        type: 'text_input',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'stripe',
          },
        ],
      },
      {
        key: 'stripe_success_completion_action',
        label: 'Navigate after success payment',
        tooltip_text:
          'Defines the action to be taken automatically after a payment is successfully processed.',
        type: 'tag_select',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'use_in_app_flow',
            condition_value: 'true',
          },
          {
            key: 'select_payment_provider',
            condition_value: 'stripe',
          },
        ],
        options: [
          {
            text: 'Go back to previous screen',
            value: 'go_back',
          },
          {
            text: 'Go back to home screen',
            value: 'go_home',
          },
        ],
      },
      {
        key: 'cleeng_publisher_id',
        label: 'Cleeng Publisher ID',
        type: 'text_input',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_environment',
        label: 'Cleeng Environment',
        type: 'select',
        options: [
          {
            text: 'Sandbox',
            value: 'sandbox',
          },
          {
            text: 'Production',
            value: 'production',
          },
        ],
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_success_completion_action',
        label: 'Navigate after success payment',
        tooltip_text:
          'Defines the action to be taken automatically after a payment is successfully processed.',
        type: 'tag_select',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'use_in_app_flow',
            condition_value: 'true',
          },
          {
            key: 'select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
        options: [
          {
            text: 'Go back to previous screen',
            value: 'go_back',
          },
          {
            text: 'Go back to home screen',
            value: 'go_home',
          },
        ],
        initial_value: 'go_back',
      },
      {
        key: 'login_screen',
        label: 'Login Screen',
        type: 'screen_selector',
        plugin_type: 'login',
        conditional_fields: [
          {
            key: 'use_in_app_flow',
            condition_value: 'true',
          },
        ],
      },
      {
        key: 'card',
        label: 'Add Card method',
        type: 'switch',
        initial_value: true,
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'googlepay',
        label: 'Add Google Pay method',
        type: 'switch',
        initial_value: false,
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'applepay',
        label: 'Add Apple Pay method',
        type: 'switch',
        initial_value: false,
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'paypal',
        label: 'Add Paypal payment method',
        type: 'switch',
        initial_value: false,
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_disable_payment_consent_checkbox',
        label: 'Disable payment consent checkbox',
        type: 'switch',
        initial_value: false,
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'paypal_success_url',
        label: 'Add Paypal success URL',
        type: 'text_input',
        initial_value: '',
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'Cleeng',
          },
          {
            key: 'paypal',
            condition_value: 'true',
          },
        ],
      },
      {
        key: 'paypal_cancel_url',
        label: 'Add Paypal cancel URL',
        type: 'text_input',
        initial_value: '',
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'Cleeng',
          },
          {
            key: 'paypal',
            condition_value: 'true',
          },
        ],
      },
      {
        key: 'paypal_error_url',
        label: 'Add Paypal error URL',
        type: 'text_input',
        initial_value: '',
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'Cleeng',
          },
          {
            key: 'paypal',
            condition_value: 'true',
          },
        ],
      },
      {
        key: 'hidden_payments',
        label: 'Hidden payments',
        tooltip_text:
          'Comma separated list of hidden payments Ids. Ids could be provided by Cleeng in case needed',
        type: 'text_input',
        initial_value: '',
        conditional_fields: [
          {
            key: 'select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
    ],
  },
  localizations: {
    fields: [
      {
        key: 'cleeng_title',
        label: 'Default label',
        type: 'text_input',
        label_tooltip: 'Storefront title',
        initial_value: 'Subscribe',
      },
    ],
  },
  styles: {
    fields: [
      {
        key: 'cleeng_general_font_color',
        label: 'General Font Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_pay_button_bg_color',
        label: 'Pay button background color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_card_input_color',
        label: 'Card Input Font Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_offer_card_title_font_color',
        label: 'Offer Card Title Font Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_offer_card_description_font_color',
        label: 'Offer Card Description Font Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_offer_card_price_font_color',
        label: 'Offer Card Price Font Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_offer_card_icon_font_color',
        label: 'Offer Card Icon Font Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_coupon_input_color',
        label: 'Coupon Input Font Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_coupon_button_bg_color',
        label: 'Coupon Button Background Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_coupon_button_color',
        label: 'Coupon Button Font Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(255, 255, 255, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_summary_item_font_color',
        label: 'Payment Summary Item Font Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_summary_total_font_color',
        label: 'Payment Summary Total Font Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 1)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_success_color',
        label: 'Buttons BG color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(81, 83, 100, 1)',
        tooltip_text: 'Buttons background color (pay buttons)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_primary_color',
        label: 'Primary Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 1)',
        tooltip_text:
          'Mediastore primary theme color (e.g. background of apply coupon button)',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_loader_color',
        label: 'Loader Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(255, 255, 255, 1)',
        tooltip_text: 'Color of the loader',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'cleeng_error_color',
        label: 'Errors Color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 1)',
        tooltip_text: 'Color of the error message',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
    ],
  },
  data: {
    fields: [
      {
        key: 'entitlements_feed_url',
        label: 'Entitlements Feed URL',
        type: 'data_source_selector',
      },
      {
        key: 'authentication_feed_url',
        label: 'Authentication Feed URL',
        type: 'data_source_selector',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/use_in_app_flow',
            condition_value: true,
          },
          {
            key: 'general/select_payment_provider',
            condition_value: 'Cleeng',
          },
        ],
      },
      {
        key: 'transaction_feed_url',
        label: 'Transaction Endpoint',
        type: 'data_source_selector',
        rules: 'conditional',
        conditional_fields: [
          {
            key: 'general/select_payment_provider',
            condition_value: 'zype',
          },
          // TO DO: confirm conditional field config
          // {
          //   key: 'general/select_payment_provider',
          //   condition_value: "brightcove",
          // },
        ],
      },
    ],
  },
} as const;

export default manifest;
