import { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from '@remix-run/react';
import type { Stripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';

type StripeProviderProps = {
  config: {
    general: {
      stripe_publishable_key: string;
    };
  };
  localizations: {
    payment_form_title?: string;
    [key: string]: string | undefined;
  };
};

const StripeProvider = ({ config, localizations }: StripeProviderProps) => {
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const stripePublishableKey = config?.general?.stripe_publishable_key;

  // Create payment intent
  const createPaymentSession = useCallback(async () => {
    const priceId = searchParams.get('productId');
    if (!priceId) {
      setError('No product ID provided');
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch('/api/stripe-create-payment-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          priceId,
          returnUrl: `${window.location.origin}/payment?session_id={CHECKOUT_SESSION_ID}`,
          hosted: false,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create payment session');
      }

      const data = await response.json();
      if (data.redirectUrl) {
        window.location.href = data.redirectUrl;
      } else {
        setClientSecret(data.clientSecret);

        if (stripePublishableKey) {
          const { loadStripe } = await import('@stripe/stripe-js');
          setStripePromise(loadStripe(stripePublishableKey));
        }
      }
    } catch (error) {
      console.error('Error creating payment session:', error);
      setError(
        error instanceof Error ? error.message : 'An unexpected error occurred'
      );
    } finally {
      setIsLoading(false);
    }
  }, [searchParams, stripePublishableKey]);

  useEffect(() => {
    setIsLoading(true);
    createPaymentSession();
  }, [createPaymentSession]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-4">
        <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-md border border-red-200 bg-red-50 p-4">
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  if (!clientSecret) {
    return null;
  }

  return (
    <div className="mx-auto w-full max-w-3xl">
      {stripePromise && clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

export default StripeProvider;
